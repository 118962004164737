// DataDisplay.js
import React from "react";

const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const calculateTotalInstalledFleet = (data, exclSold, showTrial) => {
  let totalCurrentFleet = 0;
  if (data) {
    data.forEach((item) => {
      if (!showTrial && (item.customer_identifier === "TRIAL")) {
        return;
      }
      totalCurrentFleet +=
        item.devices_installed_on_current_fleet +
        item.devices_installed_on_non_fleet_vehicles;
      if (exclSold) {
        totalCurrentFleet += item.devices_on_sold_vehicles;
      }
    });
  }
  return formatNumberWithCommas(totalCurrentFleet);
};

export const calculateTotalCurrentFleet = (data) => {
  let currentFleet = 0;
  if (data) {
    data.forEach((item) => {
      currentFleet += item.current_fleet;
    });
  }
  return formatNumberWithCommas(currentFleet);
};

export const calculateTotalFleetCoverage = (data) => {
  let [totalCurrentFleet, currentFleet, difference] = [0, 0, 0];

  if (data) {
    data.forEach((item) => {
      totalCurrentFleet +=
        item.devices_installed_on_current_fleet +
        item.devices_installed_on_non_fleet_vehicles +
        item.devices_on_sold_vehicles;
      currentFleet += item.current_fleet ;
      difference =
        Math.round(
          (totalCurrentFleet /
            (currentFleet +
              item.devices_installed_on_non_fleet_vehicles)) *
          1000
        ) / 10;
    });
  }

  return difference.toFixed(1) + "%";
};

export const calculateDeviceIssues = (data, inclSold, showTrial, showUnauthDis) => {
  let deviceIssues = 0;
  if (data) {
    data.forEach((item) => {
      if (!showTrial && (item.customer_identifier === "TRIAL")) {
        return;
      }
      deviceIssues += _calcIssuesByToggles(item, inclSold, showUnauthDis);
    });
  }
  return formatNumberWithCommas(deviceIssues);
};

export const calculateVehiclesNoStation = (data) => {
  let vehicles_with_null_rental_station = 0;
  if (data) {
    data.forEach((item) => {
      vehicles_with_null_rental_station += item.vehicles_with_null_rental_station;
    });
  }
  return formatNumberWithCommas(vehicles_with_null_rental_station);
};

export const calculateRentalStationsWithNoRecord = (data) => {
  let rental_stations_with_no_record = 0;
  if (data) {
    data.forEach((item) => {
      rental_stations_with_no_record += item.rental_stations_with_no_record;
    });
  }
  return formatNumberWithCommas(rental_stations_with_no_record);
};

export const calculateStationsWithNoGeofence = (data) => {
  let stations_with_no_geofence = 0;
  if (data) {
    data.forEach((item) => {
      stations_with_no_geofence += item.stations_with_no_geofence;
    });
  }
  return formatNumberWithCommas(stations_with_no_geofence);
};

const _calcIssuesByToggles = (item, inclSold, showUnauthDis) => {
    if (inclSold) {
        if (showUnauthDis) {
            return item.device_issues;
        } else {
            return item.device_issues - item.unauth_dis;
        }
    } else {
        if (showUnauthDis) {
            return item.device_issues_excl_sold;
        } else {
            return item.device_issues_excl_sold - item.unauth_dis_excl_sold;
        }
    }
}

export const calculateHealthyInstalledDevices = (data, inclSold, showTrial, showUnauthDis) => {
  let deviceIssues = 0;
  if (data) {
    data.forEach((item) => {
        deviceIssues += _calcIssuesByToggles(item, inclSold, true);
    });
  }
  let totalCurrentFleet = 0;
  if (data) {
    data.forEach((item) => {
      totalCurrentFleet +=
          item.devices_installed_on_current_fleet +
          item.devices_installed_on_non_fleet_vehicles +
          item.oem_on_current_fleet;
      if (inclSold) {
        totalCurrentFleet += item.devices_on_sold_vehicles;
      }
    });
  }
  return formatNumberWithCommas(totalCurrentFleet - deviceIssues);
};

export const deviceIssuesPercentage = (data, inclSold, showTrial, showUnauthDis) => {
  let totalCurrentFleet = 0;
  if (data) {
    data.forEach((item) => {
      if (!showTrial && (item.customer_identifier === "TRIAL")) {
        return;
      }
      totalCurrentFleet +=
        item.devices_installed_on_current_fleet +
        item.devices_installed_on_non_fleet_vehicles;
    });
  }

  let deviceIssues = 0;

  if (data && totalCurrentFleet > 0) {
    data.forEach((item) => {
      if (!showTrial && (item.customer_identifier === "TRIAL")) {
        return;
      }
      deviceIssues += _calcIssuesByToggles(item, inclSold, showUnauthDis);
    });

    const result =
      Math.round((deviceIssues / totalCurrentFleet) * 100 * 10) / 10 + "%";
    return result;
  }

  return "0%"; // Default to "0%" if there is no valid data or totalCurrentFleet is 0
};

export const calculateStockIssues = (data, inclNotTracked) => {
  var issues = 0;
  var ts = new Date('1970-01-01T00:00:00');
  if (data) {
      data.forEach((entry) => {
        for (const key in entry) {
          if (key === "timestamp") {
            const new_ts = new Date(entry["timestamp"]);
            if (new_ts > ts) {
              ts = new_ts;
              issues = 0;
            }
          } else {
            if (key !== "timestamp" && key !== "customer_identifier") {
              if (!inclNotTracked && key.startsWith("Vehicle not tracked")) {
                // Skip these entries
              } else {
                issues += entry[key];
              }
            }
          }
        }
      });
  }
  return formatNumberWithCommas(issues);
};

export const calculateUntracked = (data) => {
  var issues = 0;
  var ts = new Date('1970-01-01T00:00:00');
  if (data) {
      data.forEach((entry) => {
        for (const key in entry) {
          if (key === "timestamp") {
            const new_ts = new Date(entry["timestamp"]);
            if (new_ts > ts) {
              ts = new_ts;
              issues = 0;
            }
          } else {
            if (key.startsWith("Vehicle not tracked")) {
                issues += entry[key];
            }
          }
        }
      });
  }
  return formatNumberWithCommas(issues);
};

const DataDisplay = ({ CalcApi }) => {
  const totalInstalledFleet = calculateTotalInstalledFleet(CalcApi);
  const totalCurrentFleet = calculateTotalCurrentFleet(CalcApi);
  const totalFleetCoverage = calculateTotalFleetCoverage(CalcApi);
  const totalDeviceIssues = calculateDeviceIssues(CalcApi);
  const totalIssuesPercentage = deviceIssuesPercentage(CalcApi);
  const HealthyInstalledDevices = calculateHealthyInstalledDevices(CalcApi);

  return (
    <>
      <div>
        <span>Total Installed Fleet: </span>
        <span id="totalInstalledSum">{totalInstalledFleet}</span>
      </div>
      <div>
        <span>Total Current Fleet: </span>
        <span id="totalFleetSum">{totalCurrentFleet}</span>
      </div>
      <div>
        <span>Total Fleet Coverage: </span>
        <span id="totalFleetCoverage">{totalFleetCoverage}</span>
      </div>
      <div>
        <span>Total Device Issues: </span>
        <span id="totalDeviceIssues">{totalDeviceIssues}</span>
      </div>
      <div>
        <span>Total Device Issues Percentage: </span>
        <span id="totalIssuesPercentage">{totalIssuesPercentage}</span>
      </div>
      <div>
        <span>Healthy Installed Devices: </span>
        <span id="HealthyInstalledDevices">{HealthyInstalledDevices}</span>
      </div>
    </>
  );
};

export default DataDisplay;
